import { type Asset } from 'contentful';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  RenderNode,
} from '@contentful/rich-text-react-renderer';

export const renderBaseComponents = (): RenderNode => ({
  [BLOCKS.HR]: () => <hr className="my-3" />,
  [BLOCKS.HEADING_6]: (_, children) => <small className="text-xs">{children}</small>,
  [BLOCKS.UL_LIST]: (_, children) => (
    <ul className="ml-5 mb-5 list-disc [&_p]:mt-2 [&_p]:mb-0">{children}</ul>
  ),
  [BLOCKS.EMBEDDED_ASSET]: (_, children) => (
    <div className="-mx-4 mb-5">
      <img
        className="block w-full aspect-[13_/_9] object-cover object-center"
        src={(_.data.target as Asset).fields.file?.url?.toString() || ''}
      />
    </div>
  ),
  [INLINES.HYPERLINK]: (node, children) => {
    return (
      <a className="text-blue-500" href={node.data.uri}>
        {children}
      </a>
    );
  },
});

export const renderDoc = (doc: Document) =>
  documentToReactComponents(doc, {
    renderNode: {
      ...renderBaseComponents(),
      [BLOCKS.PARAGRAPH]: (_, children) => <p className="mb-6">{children}</p>,
      [BLOCKS.HEADING_1]: (_, children) => (
        <h1 className="text-4xl mb-3 font-bold">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <h2 className="text-2xl mb-3 font-bold">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (_, children) => <h3 className="text-xl mb-3">{children}</h3>,
    },
  });
