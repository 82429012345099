import { ITranslation } from "./types";

export const translateTitle = (t: ITranslation, title?: string) => {
  const { birthMarkOrLiverSpot, other, rashOrSimilar } =
    t.web.cases.case.title.titles;

  switch (title) {
    case "Födelsemärke/leverfläck":
      return birthMarkOrLiverSpot;
    case "Utslag eller liknande":
      return rashOrSimilar;
    case "Annat":
      return other;
    default:
      return title;
  }
};

export const translateQuestion = (t: ITranslation, question: string) => {
  const { acceptTerms, helpWith, describe, willRespondSoon, tooBad } =
    t.common.case.questions;

  switch (question.trim()) {
    case "Acceptera villkoren nedan för att använda tjänsten.":
      return acceptTerms.question;
    case "Vad vill du ha hjälp med?":
      return helpWith;
    case "Beskriv ditt hudproblem":
      return describe;
    case "Våra hudläkare är snart redo att göra en bedömning av ditt hudproblem!":
      return willRespondSoon;
    case "Vad synd att du inte vill gå vidare :(":
      return tooBad;
    default:
      return "N/A";
  }
};
