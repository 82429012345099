'use client';

import mixpanel from 'mixpanel-browser';
import { usePathname } from 'next/navigation';
import { FC, PropsWithChildren, useEffect } from 'react';
import { devMode } from 'ui/utils';

export const MixpanelProvider: FC<PropsWithChildren> = ({ children }) => {
  const pathname = usePathname();
  useEffect(() => {
    if (devMode()) return;
    const token = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
    if (!token) {
      console.debug('MIXPANEL not configured.');
    } else {
      mixpanel.init(token, {
        debug: true,
        track_pageview: true,
        persistence: 'localStorage',
      });
    }
  }, []);
  useEffect(() => {
    if (devMode()) return;
    mixpanel.track('pageview', { page: pathname });
    console.info('mixpanel', 'event:pageview', pathname);
  }, [pathname]);
  return <>{children}</>;
};
