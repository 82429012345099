export const debounce = <T extends Function>(fn: T, ms: number) => {
  let to: NodeJS.Timeout | null = null;

  const dFn = (...args: any[]) => {
    if (to) clearTimeout(to);
    to = setTimeout(() => fn(...args), ms);
  };

  return dFn as unknown as T;
};
