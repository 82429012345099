'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { useMarketingEvent } from '@/hooks/useMarketingEvent';

export default function FacebookEvents() {
  const { sendEvent } = useMarketingEvent();
  const pathname = usePathname();
  useEffect(() => {
    sendEvent('PageView', {
      path: pathname,
    });
  }, [pathname]);

  return null;
}
