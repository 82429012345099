"use client";

import { FC, createContext, PropsWithChildren, useContext } from "react";

import { translateTitle } from "./utils";

import { ITranslation } from "./types";

export const TranslationContext = createContext<{
  translation: ITranslation;
  locale: string;
}>({
  translation: {} as ITranslation,
  locale: "",
});

export const TranslationProvider: FC<
  { translation: ITranslation; locale: string } & PropsWithChildren
> = ({ translation, locale, children }) => (
  <TranslationContext.Provider
    value={{ translation, locale }}
    children={children}
  />
);

export const useTranslation = () => useContext(TranslationContext).translation;
export const useLocale = () => useContext(TranslationContext).locale;

export const useTranslatedTitle = (title?: string) => {
  const t = useTranslation();

  return translateTitle(t, title);
};
