import { useRef } from 'react';

import {
  concat,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { getAuthHeaders } from '../../utils';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { IMRefreshToken, REFRESH_TOKEN } from './mutations';
// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { isTabActive, tokenAboutToExpire, tokenExpired } from '../../utils';
import { LS_ACCESS_TOKEN, LS_REFRESH_TOKEN, OP_REFRESH_TOKEN } from '../../constants';

const baseRedirectUrl = process.env.NEXT_PUBLIC_REDIRECT_URL || '/onboarding/bankid';

type IgnoreRedirectCallback = (location: Location) => boolean;

const clearAndRedirect = (ignoreRedirect?: IgnoreRedirectCallback) => {
  localStorage.removeItem(LS_ACCESS_TOKEN);
  localStorage.removeItem(LS_REFRESH_TOKEN);

  if (!ignoreRedirect || !ignoreRedirect(window.location)) {
    const redirectTo = window.location.pathname + window.location.search;
    const searchParams = new URLSearchParams();
    searchParams.set('redirect', redirectTo);

    window.location.replace(`${baseRedirectUrl}?` + searchParams.toString());
  } else {
    window.location.href = '' + window.location.href;
  }
};

export const useApolloAuthClient = (ignoreRedirect?: IgnoreRedirectCallback) => {
  const uri = process.env.NEXT_PUBLIC_BACKEND_API ?? 'http://localhost:3200/graphql';

  const uploadLink = createUploadLink({
    uri,
    headers: { 'Apollo-Require-Preflight': 'true' },
  });

  const customHeadersLink = setContext(({ context }, pvContext) => {
    if (context && 'headers' in context) {
      return {
        ...pvContext,
        headers: {
          ...pvContext.headers,
          ...context.headers,
        },
      };
    }
    return pvContext;
  });

  const authLink = setContext(async ({ operationName }, { headers, ...rest }) => {
    const refreshingTokens = operationName === OP_REFRESH_TOKEN;
    let token = localStorage.getItem(
      refreshingTokens ? LS_REFRESH_TOKEN : LS_ACCESS_TOKEN,
    );

    if (!token) return { headers, ...rest };

    const hasRefreshToken = !!localStorage.getItem(LS_REFRESH_TOKEN);

    if (
      isTabActive() &&
      hasRefreshToken &&
      operationName !== OP_REFRESH_TOKEN &&
      (tokenExpired(token) || tokenAboutToExpire(token))
    ) {
      try {
        const { accessToken: newAccessToken } = await refreshAccessToken();
        token = newAccessToken;
      } catch (ex) {
        clearAndRedirect(ignoreRedirect);
      }
    }

    return {
      ...rest,
      headers: {
        ...headers,
        ...getAuthHeaders(token, refreshingTokens),
      },
    };
  });

  const client = useRef<ApolloClient<NormalizedCacheObject>>();

  const refreshAccessToken = async () => {
    if (!client.current) throw new Error();

    const { data } = await client.current.mutate<IMRefreshToken>({
      mutation: REFRESH_TOKEN,
      variables: {},
    });

    const tokens = data?.refreshToken;

    if (!tokens) throw new Error();

    localStorage.setItem(LS_ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(LS_REFRESH_TOKEN, tokens.refreshToken);

    return tokens;
  };

  const errorLink = onError(({ graphQLErrors, forward, operation }) => {
    if (!graphQLErrors?.length) return;

    const unauthorized = graphQLErrors?.some(
      graphqlError => graphqlError.message === 'Unauthorized',
    );

    if (unauthorized) clearAndRedirect();
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Treatment: {
        keyFields: ['clinicianCaseEntryId'],
      },
    },
  });
  const link = concat(
    errorLink,
    concat(concat(customHeadersLink, authLink), uploadLink),
  );

  if (!client.current) client.current = new ApolloClient({ link, cache });

  return client.current;
};
