import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { usePathname } from 'next/navigation';

const SEND_EVENT = gql`
  mutation SEND_MARKETING_EVENT(
    $eventName: String!
    $eventSourceUrl: String!
    $customData: JSON!
    $timestamp: DateTime!
  ) {
    sendMarketingEvent(
      eventName: $eventName
      eventSourceUrl: $eventSourceUrl
      customData: $customData
      timestamp: $timestamp
    )
  }
`;

const useMarketingEvent = () => {
  const [sendEventMutation] = useMutation(SEND_EVENT);
  const pathname = usePathname();

  const sendEvent = async (eventName: string, customData: object) => {
    await sendEventMutation({
      variables: {
        eventName,
        eventSourceUrl: pathname,
        customData,
        timestamp: new Date().getTime(),
      },
    });
  };
  return { sendEvent };
};

export { useMarketingEvent };
