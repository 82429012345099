"use client";

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";

import jwt from "jsonwebtoken";

type UserAuthContextType = {
  decoded: any;
  token?: string;
  logout: () => void;
  setToken: React.Dispatch<UserAuthContextType["token"]>;
};

const UserAuthContext = createContext<UserAuthContextType>({
  decoded: {},
  logout: () => {},
  setToken: () => {},
});

export const UserAuthWrapper = ({ children }) => {
  const [token, setTokenInState] = useState<string>();

  useEffect(() => {
    const tokenFromLS = localStorage.getItem("user_token");
    if (tokenFromLS) {
      setTokenInState(tokenFromLS);
    }
  }, []);

  const setToken = (token?: string) => {
    setTokenInState(token);
    const key = "user_token";
    if (!token) localStorage.removeItem(key);
    else localStorage.setItem(key, token);
  };

  const logout = useCallback(() => {
    setToken();
    localStorage.removeItem("user_token");
  }, []);

  const decoded = token ? jwt.decode(token) : {};

  const context = {
    token,
    decoded,
    logout,
    setToken,
  };

  return (
    <UserAuthContext.Provider value={context}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuthContext = () => {
  return useContext(UserAuthContext);
};
