'use client';

import { ApolloProvider } from '@apollo/client';
import { FC, PropsWithChildren } from 'react';
import { useApolloAuthClient } from 'ui/hooks/useApolloAuthClient';

export const ApolloProviderWithAuth: FC<PropsWithChildren> = ({ children }) => {
  const client = useApolloAuthClient(location => {
    const regex = /^\/(case|profile|anamnesis|create-(case|partner))(\/.*)?/gi;
    if (regex.test(location.pathname)) return false;

    return true;
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
