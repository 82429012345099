import { gql } from '@apollo/client';

export interface IMRefreshToken {
  refreshToken?: {
    accessToken: string;
    refreshToken: string;
  };
}
export const REFRESH_TOKEN = gql`
  mutation REFRESH_TOKEN {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;
